import React, { useContext, useEffect ,useState} from "react";
import { Alert, Container, Row, Col, Card, CardBody, Button ,Table, Label} from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Badge, Spinner, Pagination, PaginationItem, PaginationLink, Modal,ModalBody, UncontrolledPopover, PopoverHeader, PopoverBody, Breadcrumb, BreadcrumbItem } from 'reactstrap';

import { AvForm, AvField } from "availity-reactstrap-validation";
import {
   ModalContext,
   TempDataContext,
   FormDataContext,
   BusinessDataContext
} from "../../contexts/Contexts";
import { formatCurrency } from "../../helpers/utils";

const Payments = (props) => {
  const { loaderDispatch } = useContext(ModalContext);
  const { tempData, tempDataDispatch } = useContext(TempDataContext);
  const { businessData, businessDataDispatch } = useContext(BusinessDataContext);  
  const { formData, formDataDispatch } = useContext(FormDataContext);

  document.title = 'Subscriptions-DataNow';
  
  var interval;

  const setRefreshInterval=(refreshTime)=>{
   clearInterval(interval); // 
   if(refreshTime){
     interval = setInterval(function() {
       fetchTransactions(1);
     }, refreshTime);
    
   }
  } 

  const formOnChange = (e) => {
    formDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: e.target.name, value: e.target.value },
    });
  };
  
  const transactionSelect = (transaction, edit=false) => {
    formDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: "editStatus", value:transaction.status },
    });

    tempDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: 'transaction', value: edit? {...transaction, edit:true}: transaction },
    });

    tempDataDispatch({type: "SET_FORM_DATA",data: { name: 'modalOpen', value: true}});
   }


  const toggleFilter = (e) => {
    e.preventDefault();

    tempDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: 'showFilter', value: !tempData.showFilter },
    });
  };
  
  const navigateToPage = (page) => {
    
    tempDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: 'transactions', value: false},
    });

    fetchTransactions(page)
  }
  
  const handleSubmit = (e) => {
    e.persist();
    e.preventDefault();
    fetchTransactions(1);
  };

  useEffect(() => {
    if(!tempData.subscriptionLoaded ) {
      fetchTransactions(1);
      tempDataDispatch({
        type: "SET_FORM_DATA",
        data: { name: 'subscriptionLoaded', value: true },
      });
    }
  }, []);

  

  const paymentMethods = [
    "ATM",
    "WALLET",
    "ADMIN PURCHASE",
  ];
  const transactionStatus=[
    'Delivered',
    'Paying',
    'Charging Merchant',
    'Queued ',
    'Processing',
    'Reversed',
    'Failed',
    'Cancelled',
  ]
  
  const transactionTypes = [
    "Data Purchase",
    "Airtime Purchase",
    "Cable TV Subscription",
    "Electricity Bill Payment",
  ];
  

  
const ConfirmationModal = () => {
  const confirm=()=>{
      tempDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: "transactions", value:tempData.transactions.map((transaction) => {
       if(transaction.id===tempData.transaction.id) transaction.reversing=true;
       return transaction;
      } 
    )},
      });

    tempDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: "isModalOpen", value:false },
    });
 

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + businessData.token);
    var urlencoded = new URLSearchParams();
    urlencoded.append("status", String(5));
     var requestOptions = {
        method: "PATCH",
        headers: myHeaders,
        body: urlencoded,
       
    };
   
    var url="";

    if(tempData.transaction.transaction_type*1===0)
     url=  localStorage.getItem("apiURL") + "data_subscriptions/" + tempData.transaction.id
     if(tempData.transaction.transaction_type*1===1)
     url=  localStorage.getItem("apiURL") + "airtime_subscriptions/" + tempData.transaction.id
     if(tempData.transaction.transaction_type*1===2)
     url=  localStorage.getItem("apiURL") + "cable_subscriptions/" + tempData.transaction.id
     if(tempData.transaction.transaction_type*1===3)
     url=  localStorage.getItem("apiURL") + "electric_subscriptions/" + tempData.transaction.id

    fetch(
      url,
      requestOptions
      )
      .then((response) => (response = response.text()))
      .then((response) => {
        const data = JSON.parse(response);
        console.log(data);
        if (data.status === "success") {
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: "transactions", value:tempData.transactions.map((transaction) => {
             if(transaction.id===tempData.transaction.id) return data.data
             return transaction;
            } 
          )},
            });
 
        }   
        else if (data.message === "Token Expired"){
          props.history.push('/signout');    
      }
      
      })
      .catch((error) => {
        console.log("error", error);
      });
     




  }

  const decline=()=>{
    tempDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: "isModalOpen", value:false },
    });
  }
  return (
         <Modal  
        isOpen={tempData.isModalOpen}   backdrop="static" keyboard={false} centered={true}>
         <p className="text-danger text-center" >
        <i className="mdi mdi-alert-circle-outline  " style={{fontSize:120}}></i>
     </p>
     <p className="text-center" >Confirm reversal. Are you sure?</p>
    <p className="text-center m-0">
       <button className="btn btn-danger m-2" onClick={confirm}>Yes</button>
     <button  className="btn btn-info m-2" onClick={decline}>No</button>
     </p>
        </Modal>
     );
  }

  const Transaction = (props) => {
    const reverse=()=>{
      tempDataDispatch({
        type: "SET_FORM_DATA",
        data: { name: 'transaction', value: props.transaction },
      });

  tempDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: "isModalOpen", value:true },
    });
  }


    return (
      <React.Fragment >
       <tr  style={{ cursor:'pointer' }}>
      <th  onClick={()=>{transactionSelect(props.transaction)}}>{props.transaction.status === '5' && (<label className="badge   badge-primary">
          {transactionStatus[props.transaction.status]}</label>) }
          {props.transaction.status === '0' && (<label className="badge   badge-success">
          {transactionStatus[props.transaction.status]}</label>) }
          {props.transaction.status === '4' && (<label className="badge   badge-info">
          {transactionStatus[props.transaction.status]}</label>) }
          {(props.transaction.status === '6' || props.transaction.status === '7') && (<label className="badge   badge-danger">
          {transactionStatus[props.transaction.status]}</label>) }
          {(props.transaction.status === '1' ||props.transaction.status === '2'|| props.transaction.status === '3')
             && (<label className="badge   badge-warning text-dark">
          {transactionStatus[props.transaction.status]}</label>)}
            </th>
            <td  onClick={()=>{transactionSelect(props.transaction)}}>{transactionTypes[props.transaction.transaction_type]}</td>
            <td  onClick={()=>{transactionSelect(props.transaction)}}>{props.transaction.transaction_type*1 !==3 ? props.transaction.phone_number : props.transaction.plan_id}</td>
            <td  onClick={()=>{transactionSelect(props.transaction)}}>{props.transaction.network}</td>
            <td  onClick={()=>{transactionSelect(props.transaction)}}>  {props.transaction.transaction_type==='0' && props.transaction.plan_id}
            {props.transaction.transaction_type*1===1 && formatCurrency(props.transaction.airtime_value)}
            {props.transaction.transaction_type*1===2 && props.transaction.plan_id}
            {props.transaction.transaction_type*1===3 && props.transaction.phone_number}</td>

              <td  onClick={()=>{transactionSelect(props.transaction)}}>₦{formatCurrency(props.transaction.amount)}</td>
              <td  onClick={()=>{transactionSelect(props.transaction)}}>{paymentMethods[props.transaction.payment_method]}</td>
              <td  onClick={()=>{transactionSelect(props.transaction)}}>{props.transaction.created_at}</td>
              <td  onClick={()=>{transactionSelect(props.transaction)}}>{props.transaction.source}</td>
              <td > {(props.transaction.status === '2' ||props.transaction.status === '3' ||
              props.transaction.status === '6' ||props.transaction.status === '7' )
             && ( <div>
              {props.transaction.reversing ? "Reversing..." : (<Label style={{cursor:'pointer'}} onClick={()=>{reverse()}} className="text-danger">Reverse Transaction</Label>)}  </div>)}
            </td>
       </tr>
              </React.Fragment>
              );
            };
            
            const fetchTransactions = (page) => {
              loaderDispatch({
                type: "SET_LOADER",
                data: { text: "Retrieving transactions...", isLoading: true },
              });
              var myHeaders = new Headers();
              myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
              myHeaders.append("Accept", "application/json");
              myHeaders.append("Authorization", "Bearer " + businessData.token);
              var parameter = "";
              parameter += formData.search ? '&search=' + formData.search : '';
              parameter += formData.transactionStatus ? '&status=' + formData.transactionStatus : '';
              parameter += formData.planId ? '&plan_id=' + formData.planId : '';
              parameter += formData.network ? '&network=' + formData.network : '';
              parameter += formData.transactionType ? '&transaction_type=' + formData.transactionType : '';
              parameter += formData.paymentMethod ? '&payment_method=' + formData.paymentMethod : '';
              parameter += formData.pageSize ? '&size=' + formData.pageSize : '';
              parameter += formData.dateFrom ? '&date_from=' + formData.dateFrom : '';
              parameter += formData.dateTo ? '&date_to=' + formData.dateTo : '';
              parameter += '&page=' + page ;
              var requestOptions = {
                method: "GET",
                headers: myHeaders,
               
              };
              fetch(
                localStorage.getItem("apiURL") + "subscriptions?" + parameter,
                requestOptions
                )
                .then((response) => (response = response.text()))
                .then((response) => {
                  const data = JSON.parse(response);
                  loaderDispatch({
                    type: "SET_LOADER",
                    data: { text: "", isLoading: false },
                  });
                  console.log(data);
                  if (data.status === "success") {
                    const objectValues = Object.values(data.data.data);
                    console.log(objectValues);
                    tempDataDispatch({
                      type: "SET_FORM_DATA",
                      data: { name: "transactions", value: objectValues },
                    });
                    tempDataDispatch({
                      type: "SET_FORM_DATA",
                      data: { name: "current_page", value: data.data.current_page },
                    });
                    tempDataDispatch({
                      type: "SET_FORM_DATA",
                      data: { name: "last_page", value: data.data.last_page },
                    });
                    tempDataDispatch({
                      type: "SET_FORM_DATA",
                      data: { name: "per_page", value: data.data.per_page },
                    });
                    tempDataDispatch({
                      type: "SET_FORM_DATA",
                      data: { name: "total", value: data.data.total },
                    });
                  }
                  else if (data.message === "Token Expired"){
                    props.history.push('/signout');    
                } else if (data.errors) {
                    let errorString = "";
                    const objectValues = Object.values(data.errors);
                    objectValues.map((error) => {
                      errorString = errorString + error + ", ";
                    });
                    formDataDispatch({
                      type: "SET_ERROR",
                      data: errorString,
                    });
                  } else {
                    formDataDispatch({
                      type: "SET_ERROR",
                      data: data.message,
                    });
                  }
                })
                .catch((error) => {
                  console.log("error", error);
                  formDataDispatch({
                    type: "SET_ERROR",
                    data: "unable to connect to server",
                  });
                  loaderDispatch({
                    type: "SET_LOADER",
                    data: { text: "", isLoading: false },
                  });
                });
              };
              
              
              return (
                <React.Fragment>
                <ConfirmationModal/>
                <Container fluid  >
                 <Row className="justify-content-center align-content-center ">
                 <Col xl="11" ml="11" sm="12" className=" p-0 m-0">
                <Card className="mini-stat text-dark shadow">
                <CardBody>
                <div className="mb-2">
                <p className="text-uppercase text-left mb-0 "><b>Subscriptions</b></p>
                <hr className="border-primary" />
                </div>
                <Link to="" onClick={toggleFilter} className="mb-0 pb-0">
                {(!tempData.showFilter) ? <p className="text-uppercase text-left mb-0 small"><i className="mdi mdi-filter "></i>Show Filters </p> :
                <p className="text-uppercase text-left mb-0 small"><i className="mdi mdi-filter-remove "></i> Hide Filters</p>}
                </Link>
                <AvForm className="mt-2" role="search" onValidSubmit={handleSubmit}>
                <Row>
                {tempData.showFilter && (
                  <React.Fragment>
                       <Col md="3" xs="6" className="form-group mb-2">
                          <select className="form-control" value={formData.transactionType} name="transactionType" onChange={formOnChange}>
                            <option value="">All Subscriptions</option>
                            <option value="0">Data Subscriptions</option>
                            <option value="1">Airtime Purchases</option>
                            <option value="2">Cable TV Subscription</option>
                            <option value="3">Electricity Bill Payments</option>
                          </select>
                        </Col>
                      <Col md="3" xs="6" className="form-group mb-2">
                          <select className="form-control" value={formData.transactionStatus} name="transactionStatus" onChange={formOnChange}>
                            <option value="">All Statuses</option>
                            {transactionStatus.map((transaction, index) => { return (<option key={{index}} value={index}>{transaction}</option>) })}
                          </select>
                        </Col>

                  <Col md="3" xs="6" className="form-group mb-2">
                  <select className="form-control" value={formData.network} name="network" onChange={formOnChange}>
                            <option value="">All Networks, Discos and TVs</option>
                            <option value="MTN">MTN</option>
                            <option value="GLO">GLO</option>
                            <option value="AIRTEL">AIRTEL</option>
                            <option value="ETISALAT">ETISALAT</option>
                            <option value="DSTV">DSTV</option>
                            <option value="GOTV">GOTV</option>
                            <option value="STARTIMES">STARTIMES</option>
                  </select>
                  </Col>
                  <Col md="3" xs="6" className="form-group mb-2">
                  <select className="form-control" value={formData.paymentMethod} name="paymentMethod" onChange={formOnChange}>
                  <option value="">All Payments</option>
                            {paymentMethods.map((payment, index) => { return (<option key={index} value={index}>{payment}</option>) })}
                  </select>
                  </Col>
               
                  <Col md="2" xs="5" className="form-group mb-2">
                  <input
                  type="date"
                  className="form-control"
                  name="dateFrom"
                  value={formData.dateFrom}
                  onChange={formOnChange}
                  />
                  </Col>
                  <label className="mt-2">To</label>
                  <Col md="2" xs="5" className="form-group mb-2">
                  <input
                  type="date"
                  className="form-control"
                  name="dateTo"
                  value={formData.dateTo}
                  onChange={formOnChange}
                  />
                  </Col>
                  <Col md="2" xs="6" className="form-group mb-2">
                  <select className="form-control" value={formData.planId} name="planId" onChange={formOnChange}>
                  <option value="">All Plans</option>
                   {tempData.network && businessData.data_plans.filter(dataPlan => dataPlan.network=== tempData.network).map((plan) => { return (<option key={plan.id} value={plan.id}>{plan.name?plan.name:plan.master_name}</option>)})}
                  {!tempData.network && businessData.data_plans.map((plan) => { return (<option key={plan.id} value={plan.id}>{plan.name?plan.name:plan.master_name}</option>)})}
                  </select>
                  </Col>
                  <Col md="2" xs="6" className="form-group mb-2">
                  <select className="form-control" value={formData.pageSize} name="pageSize" onChange={formOnChange}>
                  <option value="">15 per page</option>
                  <option value="30">30 per page</option>
                  <option value="50">50 per page</option>
                  <option value="100">100 per page</option>
                  </select>
                  </Col>
                
                  <Col md="2" xs="6" className="form-group mb-2">
                  <select className="form-control" value={formData.refreshInterval} name="refreshInterval" onChange={(e)=>{setRefreshInterval(e.target.value); formOnChange(e);}}>
                  <option value="">Manual Refresh</option>
                  <option value="20000">Every 20 Seconds</option>
                  <option value="30000">Every 30 Seconds</option>
                  <option value="60000">Every Minute</option>
                  </select>
                  </Col>
                
                  </React.Fragment>
                  )}
                  
                  <div className=" mb-0 ml-3 app-search ">
                  <input
                   type="text"
                  className="form-control bg-light text-dark shadow"
                  placeholder="Search.."
                  name="search"
                  value={formData.search}
                  onChange={(e)=>{formOnChange(e)}}
                  />
                  <button  
                  style={{marginTop:-12}}
                  type="submit"><i className="mdi mdi-magnify  mdi-24px text-primary " ></i>
                  </button>
                  </div>
                  </Row>
                  </AvForm>
                  
                  {tempData.transactions && (
                    <React.Fragment>
                    <p className="text-uppercase text-left mb-2 mt-3 small">Showing ( 
                      {tempData.per_page*tempData.current_page- tempData.per_page+1 } to {tempData.current_page*tempData.per_page-tempData.per_page+tempData.transactions.length} ) of {tempData.total} Transactions</p>
                      
                      <div className="table-rep-plugin">
                      <Table responsive striped hover>
                      <thead>
                      
                      <tr>
                      <th>Status</th>
                      <th data-priority="1">Tranc. Type</th>
                      <th data-priority="1">Number/Token</th>
                      <th data-priority="3">Operator</th>
                      <th data-priority="3">Plan</th>
                      <th data-priority="1">Amount</th>
                      <th data-priority="3">Method</th>
                      <th data-priority="3">Date</th>
                      <th data-priority="6">Source</th>
                      <th data-priority="6"></th>
                      </tr>
                      </thead>
                      <tbody>
                      
                      {tempData.transactions.map((transaction) => {
                        return <Transaction key={transaction.id} transaction={transaction} />;
                      })}
                      </tbody>
                      </Table>
                      </div>
                      
                      </React.Fragment>
                      )
                    }
                    
                    
                    {tempData.transactions &&
                      (<React.Fragment>
                        <hr className="mt-0" />
                        <p className="text-uppercase text-left mb-0 small">Showing page {tempData.current_page} of {tempData.last_page}</p>
                        <Row className="ml-1 mt-2 " >
                        <Pagination className="pagination-sm">
                        <PaginationItem disabled={tempData.current_page > 1 ? false : true} >
                        <PaginationLink first onClick={() => { navigateToPage(tempData.current_page - 1) }} />
                        </PaginationItem >
                        {tempData.current_page - 5 > 0 && (
                          <PaginationItem onClick={() => { navigateToPage(tempData.current_page - 5) }} ><PaginationLink>{tempData.current_page - 5}</PaginationLink></PaginationItem>
                          )}
                          
                          <PaginationItem active><PaginationLink>{tempData.current_page}</PaginationLink></PaginationItem>
                          
                          {tempData.last_page >= tempData.current_page + 5 && (
                            <PaginationItem onClick={() => { navigateToPage(tempData.current_page + 5) }}><PaginationLink>{tempData.current_page + 5}</PaginationLink></PaginationItem>
                            )}
                            
                            <PaginationItem  disabled={tempData.current_page === tempData.last_page ? true : false}>
                            <PaginationLink onClick={() => { navigateToPage(tempData.current_page + 1) }} last />
                            </PaginationItem>
                            </Pagination>
                            </Row>
                            </React.Fragment>)
                          }
                          
                          </CardBody>
                          </Card>
                          </Col>
                          </Row>
                          </Container>
                          {tempData.transaction && !tempData.transaction.edit &&( <Modal isOpen={tempData.modalOpen}  >
 
 <ModalBody >
<div className="mb-2 text-dark">
<button type="button" className="close"
    onClick={() => {    tempDataDispatch({type: "SET_FORM_DATA",data: { name: 'modalOpen', value: false}}); }} >
    <span aria-hidden="true">&times;</span>
  </button>
          <p className="text-uppercase text-left mb-0 " ><b>Transaction details</b></p>
        
        
          <hr className="border-primary" />
        
      <Row style={{fontSize:12}}><Col xs="6"><b>TRANSACTION TYPE</b></Col><Col xs="6"><p className="m-0 p-0">{transactionTypes[tempData.transaction.transaction_type]}</p></Col></Row>
      <Row  className="mt-2 mb-3" style={{fontSize:14}}><Col xs="6"><b>
            {tempData.transaction.transaction_type==='0' && 'DATA PLAN'}
          {tempData.transaction.transaction_type*1===1 && 'AIRTIME VALUE'}
          {tempData.transaction.transaction_type*1===2 && 'CABLE PLAN'}
          {tempData.transaction.transaction_type*1===3 && 'DISCO'}
          </b></Col><Col xs="6"><p className="m-0 p-0"> {tempData.transaction.network}&nbsp;
            {tempData.transaction.transaction_type==='0' && tempData.transaction.plan_id}
          {tempData.transaction.transaction_type*1===1 &&  formatCurrency(tempData.transaction.airtime_value)}
          {tempData.transaction.transaction_type*1===2 &&  tempData.transaction.plan_id}
          </p></Col></Row>

          <Row  className="mt-2 mb-3" style={{fontSize:14}}><Col xs="6"><b>
            {tempData.transaction.transaction_type==='0' && 'PHONE NUMBER'}
          {tempData.transaction.transaction_type*1===1 && 'PHONE NUMBER' }
          {tempData.transaction.transaction_type*1===2 && 'SMARTCARD NUMBER'}
          {tempData.transaction.transaction_type*1===3 && 'METER NUMBER'}
              
              </b></Col><Col xs="6"><p className="m-0 p-0">
                {tempData.transaction.phone_number}
                </p></Col></Row>
                {tempData.transaction.transaction_type*1===3 && (
                  <React.Fragment>
            <Row  className="mt-2 mb-3" style={{fontSize:14}}><Col xs="6"><b>METER TYPE</b></Col><Col xs="6"><p className="m-0 p-0">{tempData.transaction.airtime_value}</p></Col></Row>
            <Row  className="mt-2 mb-3" style={{fontSize:14}}><Col xs="6"><b>CUSTOMER NAME</b></Col><Col xs="6"><p className="m-0 p-0">{tempData.transaction.customer_name}</p></Col></Row>
            <Row  className="mt-2 mb-3" style={{fontSize:14}}><Col xs="6"><b>CUSTOMER ADDRESS</b></Col><Col xs="6"><p className="m-0 p-0">{tempData.transaction.address}</p></Col></Row>
            <Row  className="mt-2 mb-3" style={{fontSize:14}}><Col xs="6"><b>TOKEN</b></Col><Col xs="6"><p className="m-0 p-0"><b>{tempData.transaction.plan_id}</b></p></Col></Row>
            <Row  className="mt-2 mb-3" style={{fontSize:14}}><Col xs="6"><b>UNITS</b></Col><Col xs="6"><p className="m-0 p-0">{tempData.transaction.units}</p></Col></Row>
            </React.Fragment>
             )}
      <Row  className="mt-2 mb-3" style={{fontSize:12}}><Col xs="6"><b>PAYMENT METHOD</b></Col><Col xs="6"  ><p className="m-0 p-0">{paymentMethods[tempData.transaction.payment_method]}</p></Col></Row>
      <Row  className="mt-2 mb-3" style={{fontSize:12}}><Col xs="6"><b>AMOUNT</b></Col><Col xs="6"  ><p className="m-0 p-0">₦{formatCurrency(tempData.transaction.amount)}</p></Col></Row>
      <Row  className="mt-2 mb-3" style={{fontSize:12}}><Col xs="6"><b>TRANSACTION ID</b></Col><Col xs="6"  ><p className="m-0 p-0">{tempData.transaction.id}</p></Col></Row>
      <Row  className="mt-2 mb-3" style={{fontSize:12}}><Col xs="6"><b>SOURCE</b></Col><Col xs="6"  ><p className="m-0 p-0">{tempData.transaction.source}</p></Col></Row>
      <Row   className="mt-2 mb-3" style={{fontSize:12}}><Col xs="6"><b>DATE</b></Col><Col xs="6"><p className="m-0 p-0">{tempData.transaction.created_at}</p></Col></Row>
      <Row   className="mt-2 mb-3" style={{fontSize:12}}><Col xs="6"><b>STATUS</b></Col><Col xs="6"><p className="m-0 p-0"> {transactionStatus[tempData.transaction.status]}</p></Col></Row>
        </div>
</ModalBody>
</Modal>)
}

                          </React.Fragment>
                          );
                        };
                        
                        export default withRouter(Payments);
                        