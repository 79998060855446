import React, {  useContext } from "react";
import { Alert, Button, Col, Row, Card } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";

import {
  ModalContext,
 TempDataContext,
 BusinessDataContext
} from "../../contexts/Contexts";
import logo from '../../images/logo-sm.png'

const SignUp = (props) => {  
  const { loaderDispatch } = useContext(ModalContext);
   const { tempData, tempDataDispatch } = useContext(TempDataContext);
   const { businessData, businessDataDispatch } = useContext(BusinessDataContext);  

  document.title='Sign Up-DataNow';
  businessData.merchant && props.history.push("/dashboard");

  const formOnChange = (e) => {
    tempDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: e.target.name, value: e.target.value },
    });
  };
  const handleSubmit = (e) => {
    e.persist();
    e.preventDefault();
    loaderDispatch({
      type: "SET_LOADER",
      data: { text: "signing up...", isLoading: true },
    });

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Accept", "application/json");
    var urlencoded = new URLSearchParams();
    urlencoded.append("name", String(tempData.name));
    urlencoded.append("email", String(tempData.email));
    urlencoded.append("phone_number", String(tempData.phone_number));
    urlencoded.append("password", String(tempData.password));
    urlencoded.append("business_name", String(tempData.business_name));
    urlencoded.append("address", String(tempData.address));
    urlencoded.append("website", String(tempData.website));
    urlencoded.append("description", String(tempData.description));
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
     
    };
    fetch(
      localStorage.getItem('apiURL')+"signup",
      requestOptions
    )
      .then((response) => (response = response.text()))
      .then((response) => {
        const data = JSON.parse(response);
        console.log(data);
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
        if (data.status === "success") {
          console.log(data)

          tempDataDispatch({
            type: "SET_FORM_DATA", 
            data: { name: 'dataLoaded', value:true },
          });
          businessDataDispatch({ type: 'STORE_APP_DATA', businessData:{...data.data,token:data.token}})

        } 
        if(data.errors){
            let errorString=""
            const objectValues=Object.values(data.errors)
            objectValues.map((error)=>{errorString=errorString+error+", "})
            tempDataDispatch({
                type: "SET_ERROR",
                data: errorString,
              });
        }
        else {
          tempDataDispatch({
            type: "SET_ERROR",
            data: data.message,
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
        tempDataDispatch({
          type: "SET_ERROR",
          data: "unable to connect to server",
        });
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
      });
  };

  return (
    <React.Fragment>
      
      <div className="wrapper-page mt-4">
      <Card className="overflow-hidden account-card mx-3 shadow">
            <div className=" p-4 text-white text-center position-relative bg-primary">
        <h4 className="font-20 m-b-5 mb-4">Sign Up</h4>
            <Link to="/" className="logo logo-admin">
            <img src={logo} height="36" alt="logo" />
            </Link>
            </div>
            <div className="account-card-content">
            {tempData.error && <Alert color="danger">{tempData.error}</Alert>}
            <AvForm
              className="form-horizontal m-t-30"
              onValidSubmit={handleSubmit}
            >
                 <AvField
                name="business_name"
                label="Business Name"
                value={tempData.business_name}
                onChange={(e) => {
                  formOnChange(e);
                }}
                placeholder="Enter name"
                type="text"
                required
              />

              <AvField
                name="email"
                label="Email"
                value={tempData.email}
                onChange={(e) => {
                  formOnChange(e);
                }}
                placeholder="Enter your Email"
                type="email"
                required
              />

              <AvField
                name="name"
                label="Fullname"
                value={tempData.name}
                onChange={(e) => {
                  formOnChange(e);
                }}
                placeholder="Enter your name"
                type="text"
                required
              />

              <AvField
                name="phone_number"
                label="Phone Number"
                value={tempData.phone_number}
                onChange={(e) => {
                  formOnChange(e);
                }}
                placeholder="Enter your phone number"
                type="number"
                required
                validate={{
                     pattern: { value: '^[0-9]+$', errorMessage: 'Enter valid phone number' }
                }} />


<AvField
                name="address"
                label="Business Address"
                value={tempData.address}
                onChange={(e) => {
                  formOnChange(e);
                }}
                placeholder="Enter address"
                type="text"
                required
              />

              
<AvField
                name="website"
                label="Business Website"
                value={tempData.website}
                onChange={(e) => {
                  formOnChange(e);
                }}
                placeholder="Enter valid website"
                type="text"
                required
              />

                <p className="mb-2 mt-2"><b>Business Description (max. 255 characters)</b></p>
              <textarea name="description"  
              className="form-control"
               onChange={(e) => {
                formOnChange(e);
              }}
              >{tempData.description}</textarea>


              <AvField
                name="password"
                label="Password"
                value={tempData.password}
                onChange={(e) => {
                  formOnChange(e);
                }}
                placeholder="Enter Password"
                type="password"
                required
                validate={{ required: { value: true } }}
              />

              <AvField
                name="password1"
                type="password"
                placeholder="Re-type Password"
                errorMessage="Enter Re-password"
                validate={{
                  required: { value: true },
                  match: { value: "password" },
                }}
              />

              <Row className="form-group m-t-20">
                <Col sm="6"></Col>
                <Col sm="6" className="text-right">
                  <Button
                    className="w-md waves-effect waves-light"
                    color="primary"
                    type="submit"
                  >
                    Sign Up
                  </Button>
                </Col>
              </Row>
            </AvForm>
          </div>
        </Card>
        <div className="m-t-40 text-center">
          <p>
            have an account ?
            <Link to="/signin" className="font-500  text-primary"  >
              Signin now
            </Link>
          </p>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(SignUp);
