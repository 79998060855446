import React, { useState, useContext } from "react";
import { Alert, Button, Col, Row, Card ,Collapse, CardBody,CardHeader} from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import logoBg from '../../images/logo-dark.png'
import logo from '../../images/logo-sm.png'
import investment from '../../images/investment.png'
import wave from '../../images/wave.svg'
import ResponseModal from "../../components/ResponseModal";

import {
    ModalContext,
   TempDataContext,
   BusinessDataContext
 } from "../../contexts/Contexts";

const SignIn = (props) => {

    const { loaderDispatch } = useContext(ModalContext);
    const {   tempData, tempDataDispatch } = useContext(TempDataContext);
    const { businessData, businessDataDispatch } = useContext(BusinessDataContext);  

    document.title='Badrud\'dinil Investment';

    // businessData.merchant && props.history.push("/dashboard");
    
    const formOnChange = (e) => {
        tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: e.target.name, value: e.target.value },
        });
    };
    const handleSubmit = (e) => {
        e.persist();
        e.preventDefault();
         loaderDispatch({
            type: "SET_LOADER",
            data: { text: "signing in...", isLoading: true },
        });
        
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Accept", "application/json");
        var urlencoded = new URLSearchParams();
        urlencoded.append("identifier", String(tempData.identifier));
        urlencoded.append("password", String(tempData.password));
        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: urlencoded,
           
        };
        fetch(
            localStorage.getItem('apiURL')+"signin",
            requestOptions
            )
            .then((response) => (response = response.text()))
            .then((response) => {
                const data = JSON.parse(response);
                loaderDispatch({
                    type: "SET_LOADER",
                    data: { text: "", isLoading: false },
                });
                if (data.status === "success") {
                    console.log(data)
                tempDataDispatch({
                    type: "SET_FORM_DATA", 
                    data: { name: 'dataLoaded', value:true },
                  }); 
                   businessDataDispatch({ type: 'STORE_APP_DATA', businessData:{...data.data,token:data.token}})
               
                } else {
                    tempDataDispatch({
                        type: "SET_ERROR",
                        data: data.message, 
                    });
                }
            })
            .catch((error) => {
                console.log("error", error);
                tempDataDispatch({
                    type: "SET_ERROR",
                    data: "unable to connect to server",
                });
                loaderDispatch({
                    type: "SET_LOADER",
                    data: { text: "", isLoading: false },
                });
            });
        };
        
        const submitContact = (e) => {
            e.persist();
            e.preventDefault();
            loaderDispatch({
                type: "SET_LOADER",
                data: { text: "sending message...", isLoading: true },
            });
            
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
            myHeaders.append("Accept", "application/json");
            var urlencoded = new URLSearchParams();
            tempData.contactName && urlencoded.append("name", String(tempData.contactName));
            tempData.contactPhone && urlencoded.append("phone_number", String(tempData.contactPhone));
            tempData.contactEmail && urlencoded.append("email", String(tempData.contactEmail));
            tempData.contactMessage && urlencoded.append("email", String(tempData.contactMessage));
            urlencoded.append("password", String(tempData.password));
            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: urlencoded,
               
            };
            fetch(
                localStorage.getItem('apiURL')+"contact",
                requestOptions
                )
                .then((response) => (response = response.text()))
                .then((response) => {
                    const data = JSON.parse(response);
                    loaderDispatch({
                        type: "SET_LOADER",
                        data: { text: "", isLoading: false },
                    });
                    if (data.status === "success") {
                        tempDataDispatch({
                            type: "SET_FORM_DATA",
                            data: { name: 'responseModal', value: {isOpen:true, text :data.message} },
                        });
                    } else {
                        tempDataDispatch({
                            type: "SET_ERROR",
                            data: data.message,
                        });
                    }
                })
                .catch((error) => {
                    console.log("error", error);
                    tempDataDispatch({
                        type: "SET_ERROR",
                        data: "unable to connect to server",
                    });
                    loaderDispatch({
                        type: "SET_LOADER",
                        data: { text: "", isLoading: false },
                    });
                });
            };
            
        return (
            <React.Fragment>
                {/* <div  style={{position:'relative'}}> */}
                <div  >
                <img className=" mt-0" src={wave} style={{width:'100%',bottom:'0',position:'absolute'}} alt=""/>

                <div className="  pt-4">
                   <img src={logoBg} alt="" height="36" className="float-left mb-3 mr-3 ml-3"/><b style={{fontSize:18}}>Badrud-dinil Investments</b>
                      </div>
                <Row className="mt-5 ">
                    <Col lg="7" className="mt-5" >
                        <Row    >
                            <Col lg="5" className="text-center"><img src={investment} alt="" height="150" className="  mb-3 mr-3 ml-3"/> 
</Col>
                             <Col lg="7"  ><h2 className="m-3 text-center text-dark">Grow your savings with</h2>
                              <h2 className="m-3 text-center text-dark">Badrud'dinil Investments</h2>
                              {/* <Link   to="signup"><button className="btn m-3 text-white" style={{backgroundColor:' #f57f17'}}>Get Started</button> */}
                              <h2  className="text-center "><Link  to=""><button className="btn m-3 text-white text-center" style={{backgroundColor:' #f57f17'}}>Get Started</button>
                              </Link>
                              </h2>
                              </Col>
                            </Row>
                            </Col>
              <Col lg="5" className=" mb-0 ">
                  <div className="wrapper-page">
            <Card className="overflow-hidden account-card mx-3 shadow ">
            <div className=" p-4 text-white text-center position-relative bg-primary">
        <h4 className="font-20 m-b-5 mb-4">Sign In</h4>
            <Link to="/" className="logo logo-admin">
            <img src={logo} height="36" alt="logo" />
            </Link>
            </div>
            <div className="account-card-content">
            {tempData.error && <Alert color="danger">{tempData.error}</Alert>}
            <AvForm
            className="form-horizontal m-t-30"
            // onValidSubmit={handleSubmit}
            >
            <AvField
            name="identifier"
            label="Email or Phone Number"
            value={tempData.identifier}
            onChange={(e) => {
                formOnChange(e);
            }}
            placeholder="Email or Phone Number"
            type="text"
            required
            />
            <AvField
            name="password"
            label="Password"
            value={tempData.password}
            onChange={(e) => {
                formOnChange(e);
            }}
            placeholder="Enter Password"
            type="password"
            required
            />
            
            <Row className="form-group m-t-20">
            <Col sm="6"></Col>
            <Col sm="6" className="text-right">
            <Button
            color="primary"
            className="w-md waves-effect waves-light"
            type="submit"
            >
            Sign In
            </Button>
            </Col>
            </Row>
            
            <Row className="form-group m-t-10 mb-0">
            <Col md="12" className="m-t-20">
            {/* <Link to="/forgotPassword" >
            <i className="mdi mdi-lock"></i> Forgot your password?
            </Link> */}
            <Link to=" " >
            <i className="mdi mdi-lock"></i> Forgot your password?
            </Link>
            </Col>
            </Row>
            </AvForm>
            </div>
            </Card>
            <div className="m-t-40 text-center">
            <p className="mb-5">
            Don't have an account? 
            {/* <Link to="/signup" className="font-500 text-primary"> Signup now
            </Link> */}
              <Link to="" className="font-500 text-primary"> Signup now
            </Link>
            </p>
            </div>
            </div>
            </Col>
            </Row>
            </div>
             
           

            </React.Fragment>
            );
        };
    
        export default withRouter(SignIn);
        